import { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import BaseModal from "./BaseModal";
import { Menu, Form, Button, Input } from "antd";
import { authRequest } from "../../utils/api";
import { showAppMessage } from "../../redux/actions/app";
const { TextArea } = Input;

const Modal = ({
  application,
  showAppMessage,
  token,
  isOpen,
  hideModal,
}: any) => {
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = async () => {
    setSubmitting(true);
    const values = await form.validateFields();
    authRequest(token, {
      url: "/applications",
      method: "POST",
      data: {
        target_type: application.targetType,
        target_id: application.targetId,
        kind: application.kind,
        application_note: values.application_note,
      },
    })
      .then((res: any) => {
        showAppMessage("申请成功", "success");
        hideModal();
      })
      .catch((err: any) => {
        showAppMessage(err.message, "error");
      })
      .finally(() => setSubmitting(false));
  };
  return (
    <BaseModal isOpen={isOpen} hideModal={hideModal} showLogo={false}>
      <Menu mode="horizontal" selectedKeys={["mute"]}>
        <Menu.Item key="mute">申请恢复</Menu.Item>
      </Menu>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          label="申请理由"
          extra="请输入申请理由"
          required
          hasFeedback
          name="application_note"
          rules={[
            { required: true },
            {
              type: "string",
              min: 5,
              max: 500,
              transform: (value) => value.trim(),
            },
          ]}
        >
          <TextArea
            autoSize={{ minRows: 3, maxRows: 5 }}
            onBlur={(e) =>
              form.setFieldValue("report_note", e.target.value.trim())
            }
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={submitting} block>
            {submitting ? "提交中..." : "提交"}
          </Button>
        </Form.Item>
        <div style={{ fontSize: "11px", color: "#bdbdbd" }}>
          管理员会在 1-2 个工作日内核实处理，请耐心等待。
        </div>
      </Form>
    </BaseModal>
  );
};
const mapStateToProps = (state: any) => ({
  token: state.user.token,
  application: state.app.application,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      showAppMessage,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Modal);
