import { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import dynamic from "next/dynamic";

import { showAppMessage } from "../../redux/actions/app";
import {
  fetchApkVersions,
  updateAppVersion,
  setApkUploadModalParams,
} from "redux/actions/apk";

import { Button, Form, Upload, Spin, Radio } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import BaseModal from "./BaseModal";
import { RcFile } from "antd/lib/upload";

import "react-markdown-editor-lite/lib/index.css";
import { formatDate } from "../../utils/app";
import settings from "../../settings";

const markdownit = require("markdown-it");

const MdEditor = dynamic(() => import("react-markdown-editor-lite"), {
  ssr: false,
});
const Modal = ({
  token,
  isOpen,
  hideModal,
  showAppMessage,
  updateAppVersion,
  fetchApkVersions,
  apkUploadModalParams,
  setApkUploadModalParams,
}: any) => {
  const mdParser = markdownit({
    html: false,
    linkify: false,
    typographer: false,
  });
  const [file, setFile] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);
  const [apkVersion, setApkVersion] = useState<any>();
  const [rawReleaseNote, setRawReleaseNote] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);

  function handleEditorChange({ html, text }: any) {
    setRawReleaseNote(text);
    setApkVersion({
      ...apkVersion,
      release_note: html,
      raw_release_note: text,
    });
  }

  const handleChange = (info: any) => {
    setIsLoading(true);
    if (info.file.status === "done") {
      setIsLoading(false);
      setApkVersion(info.file.response.apk_version);
      setDisabled(info.file.response.apk_version.disabled);
    } else if (info.file.status === "error") {
      showAppMessage("文件上传失败！");
    }
  };

  const handleSubmit = () => {
    if (!rawReleaseNote) {
      showAppMessage("请输入更新说明", "error");
      return;
    }
    setSubmitting(true);
    updateAppVersion(
      token,
      {
        ...apkVersion,
        disabled,
      },
      () => {
        hideModal();
        fetchApkVersions(token, 1);
        setSubmitting(false);
        setApkUploadModalParams({
          action: "create",
          apkVersion: {},
        });
      }
    );
  };
  useEffect(() => {
    if (apkUploadModalParams && apkUploadModalParams.action === "update") {
      setApkVersion(apkUploadModalParams.apkVersion);
      setDisabled(apkUploadModalParams.apkVersion.disabled);
      setRawReleaseNote(apkUploadModalParams.apkVersion.raw_release_note);
    }
  }, [apkUploadModalParams]);

  return (
    <BaseModal
      className="apk-upload-modal"
      isOpen={isOpen}
      hideModal={() => {
        hideModal();
        setApkUploadModalParams({
          action: "create",
          apkVersion: {},
        });
      }}
      showLogo={false}
    >
      {apkUploadModalParams && apkUploadModalParams.action === "create" && (
        <>
          <Upload
            showUploadList={false}
            headers={{
              Authorization: `Bearer ${token}`,
            }}
            accept=".apk"
            action={`${settings.apiUrl}/api/v1/apk_versions`}
            beforeUpload={(file: RcFile) => {
              setFile(file);
            }}
            onChange={handleChange}
            onRemove={() => {
              setFile(undefined);
            }}
          >
            <Button icon={<UploadOutlined />}>选择 APK 文件</Button>
          </Upload>
        </>
      )}
      {isLoading && apkUploadModalParams.action === "create" && (
        <div className="text-center my-30 theme-color">
          <Spin
            tip="文件上传中，需要一些时间，请勿关闭窗口…"
            className="font-12 theme-color"
          />
        </div>
      )}
      {apkVersion && (
        <Form className="mt-20" onFinish={handleSubmit}>
          {apkUploadModalParams.action === "create" && (
            <>
              <div>名称: {apkVersion.app_name}</div>
              <div>版本号: {apkVersion.version}</div>
              <div>构建号: {apkVersion.build_number}</div>
              <div>文件大小: {apkVersion.file_size} MB</div>
              <div>包名:{apkVersion.package_name}</div>
              <div>发布时间: {formatDate(apkVersion.release_date)}</div>
              <div>地址:{apkVersion.file}</div>
              <div>
                图标:
                <img width={50} src={apkVersion.icon} />
              </div>
            </>
          )}
          <Form.Item className="w-full">
            <label>
              <div className="mt-10 font-16">
                描述
                <span className="required">*</span>
              </div>
            </label>
            <MdEditor
              value={rawReleaseNote}
              className="my-md-editor"
              style={{ height: "200px" }}
              renderHTML={(text) => mdParser.render(text)}
              onChange={handleEditorChange}
              config={{
                view: {
                  menu: true,
                  md: true,
                  html: false,
                },
              }}
              plugins={["list-unordered", "list-ordered"]}
            />
          </Form.Item>
          <Form.Item>
            <Radio.Group
              onChange={(e) => {
                setDisabled(e.target.value);
              }}
              value={disabled}
            >
              <Radio value={true}>禁用</Radio>
              <Radio value={false}>启用</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              disabled={submitting}
            >
              {submitting ? "保存中..." : "保存"}
            </Button>
          </Form.Item>
        </Form>
      )}
    </BaseModal>
  );
};
const mapStateToProps = (state: any) => ({
  token: state.user.token,
  apkUploadModalParams: state.apk.apkUploadModalParams,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      showAppMessage,
      updateAppVersion,
      fetchApkVersions,
      setApkUploadModalParams,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
