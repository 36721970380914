import { SET_APK_VERSIONS, SET_APK_VERSION_PARAMS } from "../actions/apk";

const initialState = {
  apkVersions: {},
  apkUploadModalParams: { action: "create", apkVersion: {} },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_APK_VERSIONS:
      return {
        ...state,
        apkVersions: action.apkVersions,
      };
    case SET_APK_VERSION_PARAMS:
      return {
        ...state,
        apkUploadModalParams: action.apkUploadModalParams,
      };
    default:
      return state;
  }
};
