import { request, authRequest } from "~/utils/api";
import { showAppMessage } from "~/redux/actions/app";
export const fetchApkVersionsData = (params) => {
  return request({
    url: "/apk_versions",
    params: params,
  }).then((res) => {
    const { apk_versions, pager } = res.data;
    return { apk_versions, pager };
  });
};

export const fetchApkVersion = (id) => {
  return request({
    url: `/apk_versions/${id}`,
  }).then((res) => {
    return res.data.apk_version;
  });
};

export const createApkVersion = (token, data) => {
  return authRequest(token, {
    url: "/apk_versions",
    method: "POST",
    data: data,
  }).then((res) => {
    return res.data;
  });
};

export const SET_APK_VERSIONS = "SET_APK_VERSIONS";
export const fetchApkVersions = (token, page) => {
  return (dispatch) => {
    return authRequest(token, {
      url: "/apk_versions",
      params: { page },
    }).then((res) => {
      dispatch({
        type: SET_APK_VERSIONS,
        apkVersions: res.data,
      });
    });
  };
};

export const updateAppVersion = (token, params, callback) => {
  return (dispatch) => {
    authRequest(token, {
      url: `/apk_versions/${params.id}`,
      method: "PUT",
      data: {
        apk_version: {
          raw_release_note: params.raw_release_note,
          release_note: params.release_note,
          disabled: params.disabled,
        },
      },
    }).then((res) => {
      dispatch(showAppMessage("保存成功", "success"));
      callback();
    });
  };
};

export const SET_APK_VERSION_PARAMS = "SET_APK_VERSION_PARAMS";
export const setApkUploadModalParams = (apkUploadModalParams) => {
  return {
    type: SET_APK_VERSION_PARAMS,
    apkUploadModalParams,
  };
};
